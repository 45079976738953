/** @jsx jsx */
import { jsx } from "theme-ui"
import { memo } from "react"
import { Row, Col } from "react-flexbox-grid"
import { PageProps } from "gatsby"

import Layout from "components/layout"
import ArticleImage from "components/article-image"
import SEO from "components/seo"
import Ads from "components/ads"
import Calculator from "components/calculator"
import { ResponsiveTableContainer } from "components/table"
import RelatedContent from "components/related-content"
import { Well } from "components/article-elements"
import styled from "utils/styled"
import StateLink, { getLocationState, LocationState } from "components/state-link"
import { Location as HLocation } from "history"

const IndexPage = ({ location }: PageProps) => {
  const title = `Ипотечный калькулятор`
  const description = `Ипотечный калькулятор рассчитывает кредит. Таблица и график ипотечных платежей. Учитываем особенности банков в ${new Date().getFullYear()} г.`
  const locationState = getLocationState((location as any) as HLocation<LocationState>)

  return (
    <Layout locationState={locationState}>
      <SEO title={title} description={description} pathname={location.pathname} />
      <h1 id="calculator">{title}</h1>
      <div>
        <hr />
      </div>
      <Calculator location={location} />
      <Ads />
      <div id="content" sx={{ mt: 3 }}>
        <Row>
          <Col xs={12}>{renderContent(locationState)}</Col>
        </Row>
      </div>
    </Layout>
  )
}

const renderContent = (locationState: LocationState) => (
  <div>
    <hr />
    <RelatedContent locationState={locationState} />
  </div>
)

export default IndexPage
